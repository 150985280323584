import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		token: localStorage.getItem("token") || null,
	},
	getters: {
		getToken: (state) => state.token,
	},
	mutations: {
		login(state, token) {
			return new Promise((resolve) => {
				localStorage.setItem("token", token);
				state.token = token;
				resolve();
			});
		},
		logout(state) {
			return new Promise((resolve) => {
				localStorage.removeItem("token");
				state.token = null;
				resolve();
			});
		},
	},
	actions: {
		login({ commit }, { user, pass }) {
			return new Promise(async (resolve, reject) => {
				try {
					let { data: jwt } = await axios({
						method: "POST",
						url: `${process.env.VUE_APP_OUR_API_URL}/login.php`,
						data: { usuario: user, contraseña: pass, entorno: 'Luz' },
					});

					await Promise.all([commit("login", jwt.token)]);

					resolve();
				} catch (e) {
					console.log(e);
					await commit("logout");
					reject();
					return;
				}
			});
		},
		logout({ commit }) {
			return new Promise((resolve) => {
				commit("logout");
				resolve();
			});
		},
	},
	modules: {},
});
