<template>
  <v-app>
    <v-app-bar app color="primary" dark dense>
      <v-tabs optional>
        <!-- <v-tab to="/">QR</v-tab> -->
        <v-tab to="/listado">Listado</v-tab>
        <v-tab to="/formulario">Formulario</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>

      <v-tabs optional right>
        <v-tab v-if="$store.getters.getToken == null" to="/login">Login</v-tab>
        <v-tab v-else @click.stop="$store.dispatch('logout')">Logout</v-tab>
      </v-tabs>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
      <v-snackbar v-model="snack" timeout="4000" color="primary" type="error">
        {{
          msg
        }}
      </v-snackbar>
    </v-main>

    <v-footer>
      <div class="d-flex align-center">
        <v-icon left>mdi-copyright</v-icon>
        <small>AlumbraCorporacion {{ new Date().getFullYear() }}</small>
      </div>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  metaInfo() {
		return {
			title: `GENERA AlumbraEnergia | Trabaja con nosotros`,
			link: [
				{
					rel: "icon",
					href: `./img/fav_alumbraenergia.png`,
				},
			],
		};
	},

  data: () => ({
    snack: false,
    msg: '',
  }),

  mounted() {
    this.$root.$on("snack", (msg) => {
      this.snack = false;
      this.snack = true;
      this.msg = msg;
    });
  }
};
</script>
