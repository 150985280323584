import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/listado',
    name: 'Listado',
    component: () => import('../views/Listado.vue'),
		meta: { requiresAuth: true },
  },
  {
    path: '/formulario',
    name: 'Formulario',
    component: () => import('../views/Formulario.vue'),
		meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
	if (
		to.matched.some((record) => record.meta.requiresAuth) &&
		(store.getters.getToken === null || store.getters.getPermisos === null)
	) {
		let msg = store.getters.getToken != null ? "La sesión ha expirado" : "";
		await store.dispatch("logout");
		next({ name: "Login", query: { redirect: to.path, msg } });
	}

	// if (to.matched.some((record) => record.meta.acceso)) {
	// 	const acceso = to.matched.find((record) => record.meta.acceso).meta.acceso;
	// 	if ( !!( store.getters.getDecodedJwtEmpresa.permisos_b & store.getters.getPermisos[acceso] ) ) next();
	// 	else next({ name: "Home" });
	// }

	// if (to.matched.some((record) => record.meta.soloLocal)) {
	// 	if (process.env.NODE_ENV == "development") next();
	// 	else next({ name: "Home" });
	// }
	next();
});

export default router
